export const trackVisitorEvent = (eventTag, additionalInfo) => {
  if (eventTag && window.dataLayer) {
    window.dataLayer.push({
      event: eventTag,
      eventTag,
    })
  }
  if (eventTag && window.posthog) {
    window.posthog.capture(`[MW] ${eventTag}`, {
      ...additionalInfo,
      phid: window.localStorage.getItem("_phid") || "",
    })
  }
}

export const loadGoogleTagManager = function () {
  ;(function (w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : ""
    j.async = true
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
    f.parentNode.insertBefore(j, f)
  })(window, document, "script", "dataLayer", "GTM-PGXQWQR")

  if (document.getElementById("bm") != null) {
    function GetIEVersion() {
      const sAgent = window.navigator.userAgent
      const Idx = sAgent.indexOf("MSIE")

      // If IE, return version number.
      if (Idx > 0) {
        return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)))
      } else if (!!navigator.userAgent.match(/Trident\/7\./)) {
        return 11
      } else {
        return 0
      }
    }

    document.getElementById("bm").addEventListener("DOMLoaded", function () {
      if (GetIEVersion(11)) {
        const elements = document.querySelectorAll("#bm > svg")
        const element = elements[0]
        element.removeProperty("width")
        element.removeProperty("height")
        element.removeProperty("transform")
      }
    })
  }
}

// eg https://expend.com/?irclickid=Tm4w7cyEYxyNTbgQNSSAlymTUkFy-FW5m2VRUc0&sharedid=&irpid=1111&irgwc=1&impacttest=1
export const persistImpactAffiliateUrlProperties = function () {
  if (window.location.search.indexOf("irclickid=") !== -1) {
    window.localStorage.setItem(
      "impact_affiliate",
      window.location.search.slice(1)
    )
  }
}
