import {
  loadGoogleTagManager,
  persistImpactAffiliateUrlProperties,
} from "./src/utils/tracking"

function googleTag() {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(arguments)
}

function posthogCapture(event, data) {
  if (window.posthog) {
    window.posthog.capture(event, data)
  }
}

// Script loader function with JS code execution
const loadScriptWithJS = function (
  url,
  implementationCode,
  location,
  blockHtml = false
) {
  //url is URL of external file, implementationCode is the code
  //to be called from the file, location is the location to
  //insert the <script> element
  var scriptTag = document.createElement("script")
  scriptTag.src = url
  scriptTag.async = !blockHtml
  scriptTag.defer = !blockHtml
  scriptTag.onload = implementationCode
  scriptTag.onreadystatechange = implementationCode
  location.appendChild(scriptTag)
}

// <link rel="stylesheet" media="all" href="default.css" type="text/css" />
const loadScriptWithCSS = function (url, location) {
  //url is URL of external file, implementationCode is the code
  //to be called from the file, location is the location to
  //insert the <style> element
  var styleTag = document.createElement("link")
  styleTag.href = url
  styleTag.rel = "stylesheet"
  styleTag.media = "all"
  styleTag.type = "text/css"
  location.appendChild(styleTag)
}

// Listeners that fire GTAG data
const demoBookedListener = function () {
  window.addEventListener("message", function (event) {
    if (event.data.meetingBookSucceeded) {
      // Track book a demo conversion on Google Analytics
      googleTag({
        event: "meetingBookSucceeded",
        meetingBookSucceeded: event.data.meetingBookSucceeded,
        phid: window.localStorage.getItem("_phid") || "not-set", // for Impact Affiliate in GTM.
        // emailAddress:
        //   event.data.meetingsPayload.bookingResponse.postResponse.contact.email,
      })
      // Track book a demo conversion on PostHog
      posthogCapture(`[MW] demo-meeting-booked-final`, {
        phid: window.localStorage.getItem("_phid") || "not-set",
      })
    }
  })
}

const hubspotChatListener = function () {
  function onConversationsAPIReady() {
    window.HubSpotConversations.on("conversationStarted", function (payload) {
      // Track HubSpot chat conversion on Google Analytics
      googleTag({
        event: "hubspot-conversation-started",
        "hs-conversation-id": payload.conversation.conversationId,
        phid: window.localStorage.getItem("_phid") || "not-set",
      })
      // Track HubSpot chat conversion on PostHog
      posthogCapture(`[MW] hubspot-conversation-started`, {
        "hs-conversation-id": payload.conversation.conversationId,
        phid: window.localStorage.getItem("_phid") || "not-set",
      })
    })
  }
  // If external API methods are already available, use them.
  if (window.HubSpotConversations) {
    onConversationsAPIReady()
  } else {
    /*
      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
      These callbacks will be called once the external API has been initialized.
    */
    window.hsConversationsOnReady = [onConversationsAPIReady]
  }
}

const loadPostHog = function () {
  // PostHog script
  const posthogScript = document.createElement("script")
  posthogScript.setAttribute("src", "https://eu.posthog.com/static/array.js")
  posthogScript.async = true
  posthogScript.defer = true
  posthogScript.onload = () => {
    window.posthog.init("phc_uWyPtly8n8HyzWGsdr81B7sGxsRUivXY8psyXiPFgk9", {
      api_host: "https://eu.posthog.com",
      autocapture: false,
      persistence: "localStorage+cookie",
      loaded: function (posthog) {
        window.localStorage.setItem("_phid", posthog.get_distinct_id())
      },
    })
  }
  document.body.appendChild(posthogScript)
}

// https://cookie-script.com/
const loadCookieScript = function () {
  const cookieScript = document.createElement("script")
  cookieScript.setAttribute(
    "src",
    "https://cdn.cookie-script.com/s/0ca9d325dc245927ea314812db5f4b43.js"
  )
  cookieScript.setAttribute("type", "text/javascript")
  document.head.appendChild(cookieScript)
}

const trackIfLeadIsFromPaidAd = (location, posthog) => {
  const { href } = location
  const leadFromPaidAd =
    location.href.includes("gclid") ||
    location.href.includes("gbraid") ||
    location.href.includes("wbraid")
  const currentAd = leadFromPaidAd ? href : undefined

  let objectDate = new Date()
  let day = objectDate.getDate()
  let month = objectDate.getMonth()
  let year = objectDate.getFullYear()
  if (day < 10) {
    day = "0" + day
  }
  if (month < 10) {
    month = `0${month}`
  }
  let dateFormat = year + "-" + month + "-" + day

  if (leadFromPaidAd) {
    posthogCapture("[MW] Landed on website from a paid advert", {
      $set: {
        latestAd: currentAd,
      },
      $set_once: {
        leadFromPaidAd,
        firstAd: currentAd,
        firstAdDate: dateFormat,
      },
      phid: window.localStorage.getItem("_phid") || "not-set",
    })
  }
  return
}

// Gatsby Browser APIs
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser
// 1. onClientEntry
// 2. onInitialClientRender

export const onClientEntry = async () => {
  // polyfill to lazy load images on Safari
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
  // Cookie Consent is now performed via GTM.  This can be manually triggered using: CookieScript.instance.show()
  // Loaded via GTM
  // loadCookieScript()

  loadGoogleTagManager()

  // Loaded via GTM
  // loadPostHog()
}

export const onRouteUpdate = ({ location }) => {
  if (location.pathname === "/book-a-demo") {
    loadScriptWithJS(
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js",
      demoBookedListener,
      document.body
    )
  } else if ((location.pathname = "/thankyou-enterprise")) {
    googleTag({
      event: "enterpriseContactFormSubmitted",
      phid: window.localStorage.getItem("_phid") || "not-set", // for Impact Affiliate in GTM.
    })
  }
}

export const onInitialClientRender = () => {
  // Load Hubspot chat widget on initial render
  loadScriptWithJS(
    "//js.hs-scripts.com/1234567890.js",
    hubspotChatListener,
    document.body
  )

  // Load Book a demo script if user lands on book a demo
  if (window.location.pathname.includes("book-a-demo")) {
    loadScriptWithJS(
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js",
      demoBookedListener,
      document.body
    )
  }
  // Track Paid Ad Lead on PostHog
  trackIfLeadIsFromPaidAd(window.location, window.posthog)

  // Persist Impact Affiliate
  persistImpactAffiliateUrlProperties()
}
